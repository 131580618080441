import React, { ChangeEvent, useMemo } from 'react';
import { Radio } from '@material-ui/core';
import TableSmall from '../TableSmall/TableSmall';

interface HoldOptionsProps {
  assessmentsHold: {
    assessmentCode: string;
    assessmentName: string;
    held?: 'All' | number;
  }[];
  onClick: (value: ChangeEvent<HTMLInputElement>) => void;
  selectedValues: Record<string, string>;
}

const HoldOptions = ({
  assessmentsHold,
  onClick,
  selectedValues,
}: HoldOptionsProps): JSX.Element => {
  const tableData = useMemo(
    () =>
      assessmentsHold.map((item) => ({
        assessmentName: item.assessmentName,
        holdState: `${item.held || 'No'} learners`,
        hold: (
          <Radio
            value={`${item.assessmentCode}-hold`}
            onChange={onClick}
            checked={selectedValues[item.assessmentCode] === 'hold'}
            disabled={item.held === 'All'}
            color="primary"
          />
        ),
        release: (
          <Radio
            value={`${item.assessmentCode}-release`}
            onChange={onClick}
            checked={selectedValues[item.assessmentCode] === 'release'}
            disabled={!item.held}
            color="primary"
          />
        ),
      })),
    [assessmentsHold, selectedValues]
  );

  const HeaderColumns = [
    {
      header: 'Subject',
      accessor: 'assessmentName',
    },
    {
      header: 'On hold',
      accessor: 'holdState',
    },
    {
      header: 'Hold',
      accessor: 'hold',
    },
    {
      header: 'Release',
      accessor: 'release',
    },
  ];

  return <TableSmall columns={HeaderColumns} data={tableData} data-testid="hold-options-table" />;
};

HoldOptions.defaultProps = {};

export default HoldOptions;
