import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { StyledText } from './WarningMessage.styles';

interface WarningMessageProps {
  message: string;
  isError?: boolean;
}

const WarningMessage: FC<WarningMessageProps> = ({ message, isError }) => (
  <Box mt="1rem" display="flex" alignItems="center">
    <WarningRoundedIcon htmlColor={isError ? '#FC0303' : '#FCC652'} />
    <StyledText variant="caption" className="semi-bold">
      {message}
    </StyledText>
  </Box>
);

export default WarningMessage;
