import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Button } from '@cambridgeassessment/cambridge-ui';

interface DialogWindowProps {
  description: string;
  onCloseDialog: () => void;
  openDialog: boolean;
  title: string;
  ConfirmButtonOnClick:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  'data-testid'?: string;
  disableButton?: boolean;
  buttonDataTestId?: string;
  fixedWidth?: boolean;
}

const DialogWindow: React.FC<DialogWindowProps> = ({
  description,
  onCloseDialog,
  openDialog,
  title,
  'data-testid': dataTestId,
  buttonDataTestId,
  disableButton,
  ConfirmButtonOnClick,
  fixedWidth,
  children,
}) => (
  <Dialog
    open={openDialog}
    onClose={onCloseDialog}
    data-testid={dataTestId}
    id="confirm-modal"
    maxWidth={fixedWidth ? 'sm' : 'xl'}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent dividers>
      <DialogContentText>
        <Typography>{description}</Typography>
        {children}
      </DialogContentText>
      <DialogActions>
        <Button onClick={onCloseDialog} variant="text" size="small" color="primary">
          Cancel
        </Button>
        <Button
          onClick={ConfirmButtonOnClick}
          data-testid={buttonDataTestId}
          size="small"
          color="primary"
          disabled={disableButton}
        >
          Continue
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
);

DialogWindow.defaultProps = {
  'data-testid': '',
  buttonDataTestId: '',
  fixedWidth: false,
  disableButton: false,
};

export default DialogWindow;
