import { useMemo } from 'react';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';

export interface GradingProps {
  sessionId: number;
  qualificationId: number;
}

export interface GradingData {
  retrievingGradingData: boolean;
  errorRetrievingGradingData: Error | null;
  gradingAvailable: boolean;
}

export const mockGradingDataAvailable: GradingData = {
  retrievingGradingData: false,
  errorRetrievingGradingData: null,
  gradingAvailable: true,
};

export const mockGradingDataNotAvailable: GradingData = {
  retrievingGradingData: false,
  errorRetrievingGradingData: null,
  gradingAvailable: false,
};

export const useGrading = (gradingProps: GradingProps): GradingData => {
  const config = useApplicationEnv();

  const { sessionId, qualificationId } = gradingProps;

  const getGradingMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${sessionId}/qualifications/${qualificationId}/grading`,
    };
  }, [config]);

  const getGradingTask = useAsyncTaskAxios<AxiosResponse<Record<string, boolean>>>(
    axios,
    getGradingMemo
  );

  const gradingAvailable = useMemo(() => {
    if (getGradingTask.result?.data) {
      const unavailableSubject = Object.values(getGradingTask.result?.data).filter(
        (assessmentGradingStatus) => !assessmentGradingStatus
      );
      if (unavailableSubject.length) return false;
      return true;
    }
    return false;
  }, [getGradingTask.result?.data]);

  useAsyncRun(config && getGradingTask);

  return {
    gradingAvailable,
    retrievingGradingData:
      (!getGradingTask.error && !getGradingTask.result) ||
      (getGradingTask.started && getGradingTask.pending),
    errorRetrievingGradingData: getGradingTask.error,
  };
};
