import { useMemo, useState, useCallback, useEffect } from 'react';
import { useAsyncTaskAxios, useAsyncRun, useAsyncTaskTimeout } from 'react-hooks-async';
import { ReportGeneratorStatus, ReportIssuerStatus } from '@cambridgeassessment/checkpoint-dtos';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';

export interface PollerData {
  firstPollStatusBack: boolean;
  lastReportStatus?: ReportGeneratorStatus | ReportIssuerStatus;
}
const pollInterval = 5000;

export const usePollStatus = (
  sessionId: number,
  qualificationId: number,
  statusEndpoint: string,
  currentName?: string
): PollerData => {
  const config = useApplicationEnv();
  const [flipFlop, setFlipFlop] = useState(false);
  const [lastReportStatus, setLastReportPollStatus] = useState<
    ReportGeneratorStatus | ReportIssuerStatus
  >();

  const pollStatusMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${sessionId}/qualifications/${qualificationId}/${statusEndpoint}`,
    };
  }, [flipFlop, config]);

  const pollForProgress = useCallback(() => {
    setFlipFlop(!flipFlop);
  }, [flipFlop]);

  useEffect(() => {
    if (currentName) {
      setFlipFlop(!flipFlop);
      setLastReportPollStatus(undefined);
    }
  }, [currentName]);

  const progressPollTimeout = useAsyncTaskTimeout(pollForProgress, pollInterval);
  const pollStatusTask = useAsyncTaskAxios<
    AxiosResponse<ReportGeneratorStatus | ReportIssuerStatus>
  >(axios, pollStatusMemo);
  const [firstPollStatusBack, setFirstPollStatusBack] = useState<boolean>(false);

  useEffect(() => {
    if (!firstPollStatusBack && (pollStatusTask.result || pollStatusTask.error)) {
      setFirstPollStatusBack(true);
    }

    if (pollStatusTask.result) {
      if (!currentName || pollStatusTask.result?.data.executionId === currentName) {
        setLastReportPollStatus(pollStatusTask.result?.data);
      }
    }
  }, [pollStatusTask.result, pollStatusTask.error, currentName, firstPollStatusBack]);

  useAsyncRun(pollStatusTask);
  useAsyncRun(progressPollTimeout);

  return {
    firstPollStatusBack,
    lastReportStatus,
  };
};
