import { useMemo, useState, useEffect } from 'react';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';
import { isAxiosError, isNotFound } from './axios-helpers';

export interface ReleaseData {
  retrievingReleaseDate: boolean;
  errorRetrievingReleaseDate: Error | null;
  settingReleaseDate: boolean;
  errorSettingReleaseDate: Error | null;
  releaseDate?: Date;
  setReleaseDate: (newDate: Date) => void;
}
export const mockReleaseData: ReleaseData = {
  retrievingReleaseDate: false,
  errorRetrievingReleaseDate: null,
  settingReleaseDate: false,
  errorSettingReleaseDate: null,
  releaseDate: new Date(1982, 12, 17),
  setReleaseDate: () => undefined,
};

export const useResultReleaseDate = (sessionId: number, qualificationId: number): ReleaseData => {
  const config = useApplicationEnv();
  const [releaseDate, setReleaseDate] = useState<Date | undefined>();

  const setResultsReleaseDate = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${sessionId}/qualifications/${qualificationId}/setresultsrelease`,
      method: 'POST',
    };
  }, [config]);

  const setReleaseDateTask = useAsyncTaskAxios<AxiosResponse<Date>>(axios, setResultsReleaseDate);
  const getResultsReleaseDate = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${sessionId}/qualifications/${qualificationId}/resultsrelease`,
    };
  }, [config, setReleaseDateTask.result]);
  const getReleaseDateTask = useAsyncTaskAxios<AxiosResponse<Date>>(axios, getResultsReleaseDate);
  useAsyncRun(getReleaseDateTask);

  useEffect(() => {
    if (getReleaseDateTask.result) {
      setReleaseDate(new Date(getReleaseDateTask.result.data));
    }
  }, [getReleaseDateTask.result]);
  const noReleaseDateSet = useMemo(
    () =>
      getReleaseDateTask.error &&
      isAxiosError(getReleaseDateTask.error) &&
      isNotFound(getReleaseDateTask.error.response),
    [getReleaseDateTask.error]
  );

  return {
    releaseDate,
    retrievingReleaseDate:
      (!getReleaseDateTask.error && !getReleaseDateTask.result) ||
      (getReleaseDateTask.started && getReleaseDateTask.pending),

    errorRetrievingReleaseDate: noReleaseDateSet ? null : getReleaseDateTask.error,
    settingReleaseDate: setReleaseDateTask.started && setReleaseDateTask.pending,
    errorSettingReleaseDate: setReleaseDateTask.error,
    setReleaseDate: (newDate: Date) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      setReleaseDateTask.start({ data: newDate });
    },
  };
};
