import React from 'react';
import { CircularProgress, Box } from '@material-ui/core';

const Loader: React.FC = (): JSX.Element => (
  <Box
    mt="3rem"
    mb="3rem"
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    data-testid="loader"
  >
    <CircularProgress />
  </Box>
);

export default Loader;
