import React from 'react';
import { v4 as uuid } from 'uuid';
import {
  Table as MaterialTable,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  TableCellProps,
} from '@material-ui/core';
import { HeaderCell, BodyCell, Container } from './TableSmall.styles';

type TableData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}[];

interface TableProps {
  columns: {
    header: string;
    accessor: string;
    cellProps?: TableCellProps;
  }[];
  data?: TableData;
  'data-testid'?: string;
}

const TableSmall = ({ columns, data, 'data-testid': dataTestId }: TableProps): JSX.Element => (
  <TableContainer component={Container}>
    <MaterialTable aria-label="table" data-testid={dataTestId}>
      <TableHead>
        <TableRow>
          {columns.map(({ header, cellProps }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <HeaderCell key={uuid()} {...cellProps}>
              {header}
            </HeaderCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data &&
          data.map((row) => (
            <TableRow key={uuid()}>
              {columns.map(({ accessor, cellProps }) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <BodyCell key={uuid()} {...cellProps}>
                  {row[accessor]}
                </BodyCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
    </MaterialTable>
  </TableContainer>
);

TableSmall.defaultProps = {
  data: [],
  'data-testid': '',
};

export default TableSmall;
