import React from 'react';
import { Route, Switch } from 'react-router';
import { Box } from '@material-ui/core';

// Pages
import { SessionOverviewPage } from './pages/session-overview-page';
import { SessionQualificationProgressPage } from './pages/session-qualification-progress-page';
import { CentreOverviewPage } from './pages/centre-overview-page';

// Components
import { Nav } from './components/nav';
import { NotFound } from './components/NoResultsView/NoResultsView';
import PrivateRoute from './private-route';

export const RoutesWithHeader = (): JSX.Element => (
  <Box mb={1}>
    <Nav />
    <Switch>
      <Route exact path="/">
        <PrivateRoute>
          <SessionOverviewPage />
        </PrivateRoute>
      </Route>
      <Route exact path="/session/:id">
        <PrivateRoute>
          <SessionOverviewPage />
        </PrivateRoute>
      </Route>
      <Route exact path="/session/:id/qualification/:qid">
        <PrivateRoute>
          <SessionQualificationProgressPage />
        </PrivateRoute>
      </Route>
      <Route exact path="/session/:id/qualification/:qid/centre/:cid">
        <PrivateRoute>
          <CentreOverviewPage />
        </PrivateRoute>
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  </Box>
);
