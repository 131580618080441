import { useMemo } from 'react';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';
import { AssessmentMark } from '@cambridgeassessment/checkpoint-dtos';

export interface LearnerStatsProps {
  sessionId: number;
  qualificationId: number;
  centreId: string;
}

export interface LearnerStatsData {
  retrievingLearnerStats: boolean;
  errorRetrievingLearnerStats: Error | null;
  assessmentMarks?: AssessmentMark[];
}

export const useLearnerStats = (sesQualProps: LearnerStatsProps): LearnerStatsData => {
  const config = useApplicationEnv();

  const { sessionId, qualificationId, centreId } = sesQualProps;

  const getLearnerStatsMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${sessionId}/qualifications/${qualificationId}/centres/${centreId}`,
    };
  }, [config]);

  const getLearnerStatsTask = useAsyncTaskAxios<AxiosResponse<AssessmentMark[]>>(
    axios,
    getLearnerStatsMemo
  );

  useAsyncRun(config && getLearnerStatsTask);

  return {
    retrievingLearnerStats: getLearnerStatsTask.started && getLearnerStatsTask.pending,
    errorRetrievingLearnerStats: getLearnerStatsTask.error,
    assessmentMarks: getLearnerStatsTask?.result?.data,
  };
};
