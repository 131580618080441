import { Filter, CentreStats, CentreStatsSummary } from '@cambridgeassessment/checkpoint-dtos';
import { mapValues, sumBy } from 'lodash';

export const filterPredicates: { [key in Filter]: (data: CentreStats) => boolean } = {
  complete: (x: CentreStats) => x.completeCount === x.entryCount,
  incomplete: (x: CentreStats) => x.incompleteCount > 0,
  missing: (x: CentreStats) => x.missingCount > 0,
  absent: (x: CentreStats) => x.absentCount > 0,
  held: (x: CentreStats) => !!x.hasHeldLearners,
  inError: (x: CentreStats) => x.latestGeneratedError !== undefined,
  outdated: (x: CentreStats) =>
    x.latestIssued !== undefined &&
    x.latestIssued.issuedRunInfo !== undefined &&
    x.latestIssued?.issuedRunInfo?.executionId !== x.latestGenerated?.generatedRunInfo.executionId,
};

export const filterFields: { [key in Filter]: (data: CentreStats) => number } = {
  complete: (x: CentreStats) => x.completeCount,
  incomplete: (x: CentreStats) => x.incompleteCount,
  missing: (x: CentreStats) => x.missingCount,
  absent: (x: CentreStats) => x.absentCount,
  held: (x: CentreStats) => (x.hasHeldLearners ? 1 : 0),
  inError: (x: CentreStats) => (x.latestGeneratedError ? x.entryCount : 0),
  outdated: (x: CentreStats) =>
    x.latestIssued &&
    x.latestIssued.issuedRunInfo &&
    x.latestIssued?.issuedRunInfo?.executionId !== x.latestGenerated?.generatedRunInfo.executionId
      ? x.entryCount
      : 0,
};

export const groupCentreStats = (
  centreStats: CentreStats[]
): { [key in Filter]: CentreStatsSummary } | null => {
  if (centreStats.length === 0) {
    return null;
  }
  const mapped = mapValues(filterPredicates, (value, key) => {
    return {
      centreCount: centreStats.filter(value).length,
      entryCount:
        key === 'held'
          ? null
          : sumBy(centreStats.filter(value), (z) => filterFields[key as Filter](z)),
    };
  });

  return mapped as { [key in Filter]: CentreStatsSummary };
};
