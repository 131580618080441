import { styled, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Paper, TableCell, Box, TextField, Link } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { colours } from '@cambridgeassessment/cambridge-ui';

export const Container = styled(Paper)({
  padding: '24px',
});

export const HeaderCell = styled(TableCell)({
  fontWeight: 600,
  fontSize: '16px',
  borderBottom: 'none',
  paddingBottom: '4px',
  '& .MuiTableSortLabel-root': {
    position: 'relative',
  },
  '& .MuiTableSortLabel-root > svg': {
    position: 'absolute',
    fill: 'inherit',
    height: '14px',
    left: '-22px',
  },
  '&:hover': {
    cursor: 'pointer',
  },
});

export const BodyCell = styled(TableCell)({
  paddingBottom: '12px',
  paddingTop: '12px',
  fontSize: '16px',
});

export const PaginationContainer = styled(Box)({
  padding: 0,
  display: 'grid',
  justifyContent: 'end',
  marginTop: '20px',
  gridTemplateColumns: 'auto auto auto',
  justifyItems: 'end',
  alignItems: 'center',
  gridColumnGap: '12px',
  '& > nav': {
    marginRight: '4px',
  },
});

export const StyledTextField = withStyles({
  root: {
    '& .MuiInputBase-root': {
      height: '32px',
      fontSize: '14px',
      marginBottom: 0,
      width: '40px',
      border: `1px solid ${colours.monochromeLight}`,

      '& .MuiInput-input': {
        padding: '6px 10px',
        fontSize: '14px',
      },
    },
  },
})(TextField);

export const ButtonLink = withStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      textTransform: 'none',
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
  })
)(Link);

export const StyledPagination = withStyles((theme: Theme) =>
  createStyles({
    ul: {
      '& li': {
        height: '100%',
        display: 'flex',
        alignItems: 'stretch',

        '& button,div': {
          border: `1px solid ${colours.monochromeLight}`,
          borderRadius: 0,
          height: '32px',
          lineHeight: '30px',
          margin: 0,
          textAlign: 'center',
        },
        '& button': {
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              cursor: 'unset',
            },
          },
        },
        '&:not(:last-child)': {
          '& button,div': {
            borderRight: 'none',
          },
        },
        '&:last-child': {
          '& button,div': {
            borderRadius: '0 4px 4px 0',
          },
        },
        '&:first-child': {
          '& button,div': {
            borderRadius: '4px 0 0 4px',
          },
        },
      },
    },
  })
)(Pagination);
