import { LearnerMark } from '@cambridgeassessment/checkpoint-dtos';

export const learnersOnHoldList = (
  learnerHoldId: number | undefined,
  hold: boolean,
  learnerList: LearnerMark[]
): number[] => {
  const heldLearnerList = learnerList.filter((item) => item.hold).map((x) => x.candidateNumber);
  if (!learnerHoldId) {
    return heldLearnerList;
  }
  if (hold) {
    heldLearnerList.push(learnerHoldId);
    return heldLearnerList;
  }
  return heldLearnerList.filter((item) => item !== learnerHoldId);
};
