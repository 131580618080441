import React, { useMemo } from 'react';
import WarningRoundedIcon from '@material-ui/icons/Warning';
import { Tooltip, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { RunInfo } from '@cambridgeassessment/checkpoint-dtos';
import { lightFormat } from 'date-fns';

export const CheckpointTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
    padding: '1rem',
  },
}))(Tooltip);

export interface RunTooltipProps {
  generationRunInfo?: RunInfo;
  issueRunInfo?: RunInfo;
  printRunInfo?: RunInfo;
}

export const RunTooltipContent: React.FC<RunTooltipProps> = ({
  generationRunInfo,
  issueRunInfo,
}) => {
  const generationError = useMemo(() => {
    if (generationRunInfo?.error?.Cause.includes('No data available')) {
      return 'Centre incomplete';
    }
    if (generationRunInfo?.error?.Cause.includes('All subjects are on hold')) {
      return 'Centre on hold';
    }
    return 'Something went wrong generating report, if the problem persists contact BTS helpdesk';
  }, [generationRunInfo?.error]);
  return (
    <>
      {generationRunInfo && (
        <Box>
          <b>generated by: </b>
          {generationRunInfo.runBy.name}
          <br />
          <b>generated on: </b>
          {lightFormat(new Date(generationRunInfo.runAt), 'dd-MM-yyyy HH:mm')}
          {generationRunInfo.error !== undefined && (
            <>
              <br />
              <b>error: </b> {generationError}
            </>
          )}
        </Box>
      )}
      {issueRunInfo && (
        <Box>
          <b>issued by: </b>
          {issueRunInfo.runBy.name}
          <br />
          <b>issued on: </b>
          {lightFormat(new Date(issueRunInfo.runAt), 'dd-MM-yyyy HH:mm')}
          {issueRunInfo.error !== undefined && (
            <>
              <br />
              <b>error: </b> Something went wrong issuing report, if the problem persists contact
              BTS helpdesk
            </>
          )}
        </Box>
      )}
    </>
  );
};

export const WarningIcon: React.FC<RunTooltipProps> = ({ generationRunInfo, issueRunInfo }) => (
  <CheckpointTooltip
    title={<RunTooltipContent generationRunInfo={generationRunInfo} issueRunInfo={issueRunInfo} />}
    arrow
  >
    <div>
      {generationRunInfo?.error && (
        <WarningRoundedIcon
          fontSize="small"
          htmlColor={
            generationRunInfo?.error?.Cause.includes('No data available') ||
            generationRunInfo?.error?.Cause.includes('All subjects are on hold')
              ? '#FCC652'
              : '#FC0303'
          }
        />
      )}
      {issueRunInfo?.error && (
        <WarningRoundedIcon
          fontSize="small"
          htmlColor={
            issueRunInfo?.error?.Cause.includes('No data available') ||
            issueRunInfo?.error?.Cause.includes('All subjects are on hold')
              ? '#FCC652'
              : '#FC0303'
          }
        />
      )}
    </div>
  </CheckpointTooltip>
);
