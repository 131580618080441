import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Theme, Typography } from '@material-ui/core';

export interface LabelWithIndicatorProps {
  label: string;
  indicatorInMargin?: boolean;
  color?: string;
}
export interface IndicatorProps {
  color: string;
  indicatorInMargin?: boolean;
}

const useStyles = makeStyles<Theme, IndicatorProps>({
  // style rule
  indicator: (props) => ({
    backgroundColor: props.color,
    borderRadius: '50%',
    width: '12px',
    minWidth: '12px',
    marginRight: '8px',
    marginLeft: props.indicatorInMargin ? '-20px' : '0',
    height: '12px',
  }),
});

export const LabelWithIndicator: React.FC<LabelWithIndicatorProps> = ({
  label,
  color = '#FCC652',
  indicatorInMargin = false,
}): JSX.Element => {
  const classes = useStyles({ color, indicatorInMargin });
  return (
    <Box display="flex" alignItems="center">
      <div className={`${classes.indicator}`} />
      <Typography variant="body1">{label}</Typography>
    </Box>
  );
};
