import React from 'react';
import { StyledPagination, PaginationContainer, StyledTextField } from './Table.styles';

interface TablePaginationProps {
  count: number;
  page: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

const TablePagination = ({ count, page, handleChange }: TablePaginationProps): JSX.Element => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    handleChange(event, Number(event.target.value));
  };

  return (
    <PaginationContainer>
      <StyledPagination count={Math.ceil(count)} page={page} onChange={handleChange} />
      <span>Go to page:</span>
      <StyledTextField id="standard-number" type="number" onChange={handleInputChange} />
    </PaginationContainer>
  );
};

export default TablePagination;
