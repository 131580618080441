import React from 'react';
import { lightFormat } from 'date-fns';
import { Box } from '@material-ui/core';
import { RunInfo } from '@cambridgeassessment/checkpoint-dtos';

interface GeneratedInfoLabelProps {
  runInfo?: RunInfo;
}

export const GeneratedInfoLabel: React.FC<GeneratedInfoLabelProps> = ({ runInfo }) => {
  const formattedDate = runInfo ? lightFormat(new Date(runInfo.runAt), 'dd-MM-yyyy HH:mm') : '';

  return (
    <Box paddingTop="4px" display="flex" flexDirection="row" alignItems="center">
      {!runInfo ? (
        <Box paddingLeft="4px">
          <b>Not yet run</b>
        </Box>
      ) : (
        <Box paddingLeft="4px">
          <b>generated by:</b> {runInfo.runBy.name}
          <br />
          <b>generated on:</b> {formattedDate}
        </Box>
      )}
    </Box>
  );
};
