import { styled } from '@material-ui/core/styles';
import { Paper, TableCell } from '@material-ui/core';

export const Container = styled(Paper)({
  marginTop: '10px',
});

export const HeaderCell = styled(TableCell)({
  fontWeight: 600,
  borderBottom: 'none',
  paddingBottom: 0,
  '& .MuiTableSortLabel-root': {
    position: 'relative',
  },
  '& .MuiTableSortLabel-root > svg': {
    position: 'absolute',
    fill: 'inherit',
    height: '14px',
    left: '-22px',
  },
  '&:hover': {
    cursor: 'pointer',
  },
});

export const BodyCell = styled(TableCell)({
  paddingBottom: '12px',
});
