import React, { useState } from 'react';
import {
  logos,
  ProductHeader,
  ProductHeaderLogo,
  ProductTitleText,
  UserMenu,
  Button,
} from '@cambridgeassessment/cambridge-ui';
import { Box, MenuItem, Link } from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from 'oidc-react';

export const Nav: React.FC = (): JSX.Element => {
  const auth = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const handleLogOut = (): void => {
    // eslint-disable-next-line no-void
    void auth?.signOut();
    setIsOpen(false);
  };
  return (
    <ProductHeader maxWidth="lg">
      <ProductHeaderLogo>
        <Link component={RouterLink} to="/">
          <logos.CambridgeInternationalLogo />
        </Link>
      </ProductHeaderLogo>
      <ProductTitleText productName="Checkpoint Operational Dashboard" />
      <Box ml="auto">
        {auth?.userData && (
          <UserMenu
            open={isOpen}
            onClick={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            id="1"
            name={auth?.userData.profile.name || ''}
            imageSrc={auth?.userData.profile.picture}
          >
            <MenuItem onClick={handleLogOut}>Logout</MenuItem>
          </UserMenu>
        )}
        {!auth?.userData && (
          <Button
            onClick={() =>
              auth?.signIn({
                data: { targetUrl: window.location.pathname + window.location.search },
              })
            }
          >
            Login
          </Button>
        )}
      </Box>
    </ProductHeader>
  );
};
