import { styled } from '@material-ui/core/styles';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';

export const StyledSelect = styled(Select)({
  border: 'none',
  width: 'auto',
  height: 'auto',
  marginBottom: '0',
  backgroundColor: 'transparent',

  '& .MuiInput-input': {
    fontWeight: '600',
    color: '#037fac',
  },

  '& .MuiInputBase-input': {
    padding: '0 18px 0 0',
  },

  '& .Chevron-down': {
    position: 'absolute',
    right: '0',
    top: '8px',
    marginRight: '0',
    cursor: 'pointer',
  },
});

export const StyledMenuItem = styled(MenuItem)({
  '&.Mui-selected': {
    backgroundColor: '#fff',
    fontWeight: '700',
  },
});

export const SelectContainer = styled(FormControl)({
  display: 'inline-grid',
  gridTemplateColumns: 'auto auto',
  gridColumnGap: '10px',
  alignItems: 'center',
});

export const SelectLabel = styled(InputLabel)({
  fontSize: '16px',
});
