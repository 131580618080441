import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from 'oidc-react';
import { useApplicationEnv } from 'application.env/react';

export const useAxiosInterceptors = (): boolean => {
  const auth = useAuth();
  const config = useApplicationEnv();
  const [interceptorConfigured, setInterceptorConfigured] = useState(false);
  useEffect(() => {
    if (auth && auth.userData?.access_token && config?.API_DOMAIN) {
      const token = auth?.userData.access_token;
      const authInterceptor = axios.interceptors.request.use((request) => {
        if (auth !== null && request.url?.startsWith(config?.API_DOMAIN)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          request.headers = {
            ...request.headers,
            Authorization: `Bearer ${token}`,
          };
        }
        return request;
      });
      setInterceptorConfigured(true);
      return function cleanup() {
        axios.interceptors.request.eject(authInterceptor);
      };
    }
    return undefined;
  }, [auth, config]);

  return interceptorConfigured;
};
