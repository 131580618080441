import { withStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

export const StyledDatePicker = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '200px',
      height: '36px',
      '& .MuiInput-root': {
        height: '36px',
        borderColor: theme.palette.grey[500],
        marginBottom: 0,
        '& .MuiInputBase-input': {
          padding: '7px 16px',
        },
        '&.MuiInput-underline': {
          '&:before': {
            borderBottom: 'none',
          },
          '&:after': {
            borderBottom: 'none',
          },
          '&.Mui-error': {
            borderColor: theme.palette.error.main,
          },
        },
        '& .MuiInputAdornment-root': {
          marginTop: '3px',
          '& > button': {
            color: theme.palette.grey[600],
          },
        },
      },
      '& .MuiFormHelperText-root': {
        position: 'absolute',
        bottom: '-16px',
      },
    },
  })
)(KeyboardDatePicker);

export const DatePickerContainer = styled(FormControl)({
  display: 'inline-grid',
  gridTemplateColumns: 'auto auto',
  gridColumnGap: '10px',
  alignItems: 'center',
});
