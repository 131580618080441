import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => (
  <Box mt="1rem" display="flex" alignItems="flex-start">
    <WarningRoundedIcon htmlColor="#FC0303" />
    <Typography variant="subtitle1" className="bold">
      {message}
    </Typography>
  </Box>
);

export default ErrorMessage;
