/* eslint-disable @typescript-eslint/no-namespace */
import React, { FC, useMemo } from 'react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { Route } from 'react-router';
import ApplicationEnvProvider, { useApplicationEnv } from 'application.env/react';
import { ThemeProvider } from '@material-ui/core/styles';
import { AuthProvider } from 'oidc-react';
import { CssBaseline } from '@material-ui/core';
import { mainTheme, createGlobalStyle as GlobalStyle } from '@cambridgeassessment/cambridge-ui';
import { RoutesWithHeader } from './routes-with-header';
import { AppState } from './types';
/* eslint-disable @typescript-eslint/no-unused-vars */
declare global {
  namespace ApplicationEnv {
    interface Env {
      AUTH_DOMAIN: string;
      AUTH_CLIENT_ID: string;
      API_DOMAIN: string;
    }
  }
}

const AppMain: FC = (): JSX.Element => {
  const config = useApplicationEnv();
  const history = useHistory();
  if (!config) {
    return <>Loading</>;
  }

  return (
    <AuthProvider
      authority={config.AUTH_DOMAIN || ''}
      clientId={config.AUTH_CLIENT_ID}
      automaticSilentRenew
      responseType="code"
      autoSignIn={false}
      onSignIn={(userData) => {
        if (userData && userData.state) {
          const state = userData.state as AppState;
          history.push(state.targetUrl);
        }
      }}
      scope="openid profile email photoURL roles orgs platformAccess"
      redirectUri={`${window.location.origin}/`}
    >
      <Route render={() => <RoutesWithHeader />} />
    </AuthProvider>
  );
};

const App: FC = (): JSX.Element => {
  const envPath = useMemo(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      if (process.env.REACT_APP_CUSTOM_ENV) {
        return `/${process.env.REACT_APP_CUSTOM_ENV}`;
      }
      return '/local.application.env';
    }
    return '/application.env';
  }, []);
  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      <CssBaseline />
      <ApplicationEnvProvider path={envPath}>
        <Router>
          <AppMain />
        </Router>
      </ApplicationEnvProvider>
    </ThemeProvider>
  );
};

export default App;
