import { useMemo } from 'react';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import { Session } from '@cambridgeassessment/checkpoint-dtos';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';

export interface SessionInfo {
  retrievingSessions: boolean;
  errorRetrievingSessions: Error | null;
  sessions: Session[];
}

export const useSessions = (): SessionInfo => {
  const config = useApplicationEnv();
  const getSessionsMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions`,
    };
  }, [config]);

  const getSessionsTask = useAsyncTaskAxios<AxiosResponse<Session[]>>(axios, getSessionsMemo);

  useAsyncRun(config && getSessionsTask);

  return {
    retrievingSessions:
      (getSessionsTask.started && getSessionsTask.pending) ||
      (!getSessionsTask.result && !getSessionsTask.error),
    errorRetrievingSessions: getSessionsTask.error,
    sessions: getSessionsTask.result?.data || [],
  };
};
