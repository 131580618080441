import { capitalize } from 'lodash';

const capitalizeAllExcept = (sentence: string, exceptions: string[]): string => {
  const words = sentence.split(' ');
  return words
    .map((word) =>
      exceptions.some((exception) => exception.toUpperCase() === word.toUpperCase())
        ? word.toLowerCase()
        : capitalize(word)
    )
    .join(' ');
};

// eslint-disable-next-line import/prefer-default-export
export { capitalizeAllExcept };
