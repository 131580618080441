import React, { useState, useMemo, useEffect, useCallback, ChangeEvent } from 'react';
import { useApplicationEnv } from 'application.env/react';
import { Link as RouterLink, useParams, useLocation, useHistory } from 'react-router-dom';
import { orderBy, uniq } from 'lodash';
import { useDebounce } from 'use-lodash-debounce';
import WarningRoundedIcon from '@material-ui/icons/Warning';
import {
  Box,
  Container,
  Link,
  Typography,
  Tabs,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import { getQualification } from '@cambridgeassessment/checkpoint-utils';
import { Breadcrumbs, Button } from '@cambridgeassessment/cambridge-ui';
import { ExtendedFilter, LearnerMark, AssessmentMark } from '@cambridgeassessment/checkpoint-dtos';
import { useAsyncTaskAxios } from 'react-hooks-async';
import axios, { AxiosResponse } from 'axios';
import { learnersOnHoldList } from '../utils/holdLearners';
import { useSessions } from '../utils/useSessions';
import DialogWindow from '../components/DialogWindow/DialogWindow';
import { CheckpointTooltip } from '../components/WarningIcon/WarningIcon';
import SearchInput from '../components/SearchInput/SearchInput';
import NoResultsView, { Error, NotFound } from '../components/NoResultsView/NoResultsView';
import { PageLoader } from '../components/PageLoader';
import Table from '../components/Table/Table';
import { FilterTab } from '../components/Tabs/FilterTab';
import { FilterTabLabel } from '../components/Tabs/FilterTabLabel';
import Select from '../components/Select/Select';
import { useLearnerStats } from '../utils/useLearnerStats';
import { contains } from '../utils/helpers';
import Loader from '../components/Loader/Loader';
import { LabelWithIndicator } from '../components/LabelWithIndicator';
import FilterCheckbox from '../components/FilterCheckbox/FilterCheckbox';

export interface SessionCentreQualPath {
  id: string;
  qid: string;
  cid: string;
}

export const CentreOverviewPage: React.FC = (): JSX.Element => {
  const { id, qid, cid } = useParams<SessionCentreQualPath>();
  const config = useApplicationEnv();
  const [subject, setSubject] = useState<string>();
  const [holdOrReleaseModal, setHoldOrReleaseModal] = useState(false);
  const [selectedLearner, setSelectedLearner] = useState<number>();
  const [selectedLearnerOnHold, isSelectedLearnerHeld] = useState(false);
  const [holdOrReleaseUpdates, setHoldOrReleaseUpdates] = useState<Record<string, string>>({});
  const [holdCompleteModal, setHoldCompleteModal] = useState(false);
  const [e2lFilterChecked, setE2lFilterChecked] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { sessions, retrievingSessions, errorRetrievingSessions } = useSessions();
  const confirmHoldOrReleaseModal = (learnerId: number, hold: boolean): void => {
    setSelectedLearner(learnerId);
    isSelectedLearnerHeld(hold);
    setHoldOrReleaseModal(true);
  };

  const handleCloseHoldOrRelease = (): void => {
    setHoldOrReleaseUpdates({});
    setHoldOrReleaseModal(false);
  };

  const handleCloseHoldComplete = (): void => {
    setHoldOrReleaseUpdates({});
    setHoldCompleteModal(false);
  };

  const holdMemo = useMemo(() => {
    if (!config || !selectedLearner) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${id}/qualifications/${qid}/centres/${cid}/holdlearner`,
      method: 'POST',
    };
  }, [config, selectedLearner]);

  const holdTask = useAsyncTaskAxios<AxiosResponse<string>>(axios, holdMemo);

  const useQuery = (): URLSearchParams => new URLSearchParams(location.search);
  const query = useQuery();

  const currentSession = useMemo(
    () => sessions.find((session) => String(session.id) === id)?.displayName,
    [id, sessions]
  );

  const searchQuery = query.get('search') || '';
  const debouncedQuery = useDebounce(searchQuery, 300);

  const handleSearch = (value: string): void => {
    const url = `/session/${id}/qualification/${qid}/centre/${cid}`;
    if (value) {
      history.push(`${url}?search=${value}`);
    } else {
      history.push(`${url}`);
    }
  };

  const filter = useMemo<ExtendedFilter | undefined>(() => {
    const filterQuery = query.get('filter');

    if (!filterQuery) {
      return undefined;
    }

    if (Array.isArray(filterQuery)) {
      return filterQuery[0] as ExtendedFilter;
    }

    return filterQuery as ExtendedFilter;
  }, [location]);

  const { assessmentMarks, retrievingLearnerStats, errorRetrievingLearnerStats } = useLearnerStats({
    sessionId: parseInt(id, 10),
    qualificationId: parseInt(qid, 10),
    centreId: cid,
  });

  const filterRules = useMemo(
    () => ({
      complete: (learnerMarks: LearnerMark[]): LearnerMark[] =>
        learnerMarks.filter(
          (item) => !Object.values(item.marks).some((value) => !value && value !== 0)
        ),
      incomplete: (learnerMarks: LearnerMark[]): LearnerMark[] =>
        learnerMarks.filter((item) =>
          Object.values(item.marks).some((value) => !value && value !== 0)
        ),
      missing: (learnerMarks: LearnerMark[]): LearnerMark[] =>
        learnerMarks.filter((item) => Object.values(item.marks).some((value) => value === 'M')),
      absent: (learnerMarks: LearnerMark[]): LearnerMark[] =>
        learnerMarks.filter((item) => Object.values(item.marks).some((value) => value === 'A')),
      held: (learnerMarks: LearnerMark[]): LearnerMark[] =>
        learnerMarks.filter((item) => !!item.hold),
    }),
    []
  );

  const getUniqueLearners = useCallback(
    (
      assessments: AssessmentMark[],
      callback: (learnerMarks: LearnerMark[]) => LearnerMark[]
    ): number =>
      assessments?.reduce(
        (a, c) =>
          uniq([...a, ...callback(c.learnerMarks).map((learner) => learner.candidateNumber)]),
        [] as number[]
      ).length || 0,
    []
  );

  const filters = useMemo(
    () => [
      {
        value: 0 as const,
        label: 'All learners',
        count:
          assessmentMarks?.reduce(
            (a, c) => (a > c.learnerMarks.length ? a : c.learnerMarks.length),
            0
          ) || 0,
        entryCount: assessmentMarks?.reduce((a, c) => a + c.learnerMarks.length, 0) || 0,
      },
      {
        value: 'complete' as ExtendedFilter,
        label: 'Complete learners',
        count:
          (assessmentMarks?.reduce(
            (a, c) => (a > c.learnerMarks.length ? a : c.learnerMarks.length),
            0
          ) || 0) - getUniqueLearners(assessmentMarks || [], filterRules.incomplete),
        entryCount:
          assessmentMarks?.reduce((a, c) => a + filterRules.complete(c.learnerMarks).length, 0) ||
          0,
      },
      {
        value: 'incomplete' as ExtendedFilter,
        label: 'Incomplete learners',
        count: getUniqueLearners(assessmentMarks || [], filterRules.incomplete),
        entryCount:
          assessmentMarks?.reduce((a, c) => a + filterRules.incomplete(c.learnerMarks).length, 0) ||
          0,
      },
      {
        value: 'missing' as ExtendedFilter,
        label: 'Missing learners',
        count: getUniqueLearners(assessmentMarks || [], filterRules.missing),
        entryCount:
          assessmentMarks?.reduce((a, c) => a + filterRules.missing(c.learnerMarks).length, 0) || 0,
      },
      {
        value: 'absent' as ExtendedFilter,
        label: 'Absent learners',
        count: getUniqueLearners(assessmentMarks || [], filterRules.absent),
        entryCount:
          assessmentMarks?.reduce((a, c) => a + filterRules.absent(c.learnerMarks).length, 0) || 0,
      },
      {
        value: 'held' as ExtendedFilter,
        label: 'Held learners',
        count: getUniqueLearners(assessmentMarks || [], filterRules.held),
        entryCount:
          assessmentMarks?.reduce((a, c) => a + filterRules.held(c.learnerMarks).length, 0) || 0,
      },
    ],
    [assessmentMarks]
  );

  const subjects = useMemo(
    () =>
      orderBy(
        assessmentMarks?.map(({ assessmentCode, assessmentName }) => ({
          value: assessmentCode,
          label: assessmentName,
        })),
        ['label'],
        ['asc']
      ) || [],
    [assessmentMarks]
  );

  useEffect(() => {
    if (subjects && subjects.length) {
      setSubject(subjects[0].value);
    }
  }, [subjects]);

  const filterLearners = useCallback(
    (learnerMarks: LearnerMark[], filterValue?: ExtendedFilter): LearnerMark[] => {
      switch (filterValue) {
        case 'complete':
          return filterRules.complete(learnerMarks);
        case 'incomplete':
          return filterRules.incomplete(learnerMarks);
        case 'missing':
          return filterRules.missing(learnerMarks);
        case 'absent':
          return filterRules.absent(learnerMarks);
        case 'held':
          return filterRules.held(learnerMarks);
        default:
          return learnerMarks;
      }
    },
    []
  );

  const handleSingleFilter = (): void => setE2lFilterChecked(!e2lFilterChecked);

  const confirmHoldCompleteModal = async (): Promise<void> => {
    setHoldOrReleaseModal(false);
    setHoldCompleteModal(true);
    await holdTask.start({
      data: {
        assessmentHold: Object.keys(holdOrReleaseUpdates).map((key: string) => {
          const learnerList =
            assessmentMarks?.find((item) => item.assessmentCode === key)?.learnerMarks || [];
          const learnersOnHold = learnersOnHoldList(
            selectedLearner,
            !selectedLearnerOnHold,
            learnerList
          );
          return {
            assessmentCode: key,
            hold: learnersOnHold.length === 0 ? false : holdOrReleaseUpdates[key] === 'hold',
            learnersOnHold:
              learnersOnHold.length === 0 || learnersOnHold.length === learnerList.length
                ? undefined
                : learnersOnHold,
          };
        }),
      },
    });
  };

  const tableData = useMemo(() => {
    const subjectLearners =
      assessmentMarks?.find((item) => item.assessmentCode === subject)?.learnerMarks || [];

    return filterLearners(subjectLearners, filter).filter(
      (item) =>
        (contains(item.name, debouncedQuery) ||
          contains(String(item.candidateNumber), debouncedQuery)) &&
        (e2lFilterChecked && subject === getQualification(parseInt(qid, 10)).e2l
          ? item.teachingGroupNumber.startsWith('E')
          : true)
    );
  }, [assessmentMarks, subject, location, debouncedQuery, e2lFilterChecked]);

  const headerColumns = useMemo(
    () => [
      {
        header: 'Learner name',
        accessor: 'learnername',
        renderCell: (row: LearnerMark) =>
          row.hold === true ? (
            <LabelWithIndicator label={row.name} indicatorInMargin />
          ) : (
            `${row.name}`
          ),
      },
      {
        header: 'No.',
        accessor: 'candidateNumber',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Teaching Group',
        accessor: 'teachingGroupNumber',
        cellProps: {
          align: 'center' as const,
        },
      },
      ...(tableData && tableData.length > 0 ? Object.keys(tableData[0].marks) : []).map(
        (key: string) => ({
          header: `Component ${key}`,
          accessor: key,
          renderCell: (row: LearnerMark) => {
            if (row.marks[key] === null && row.itemMarks[key] !== null) {
              return (
                <CheckpointTooltip
                  title="No mark in EPS but mark in ESM, fix mark in EPS to resolve"
                  arrow
                >
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <WarningRoundedIcon fontSize="small" htmlColor="#FCC652" />
                    <Box pl={1}>
                      <b>{row.itemMarks[key]}</b>
                    </Box>
                  </Box>
                </CheckpointTooltip>
              );
            }
            if (
              row.marks[key] !== null &&
              row.itemMarks[key] !== null &&
              row.marks[key] !== row.itemMarks[key]
            ) {
              return (
                <CheckpointTooltip title="EPS mark differs from ESM Mark" arrow>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <b>{row.marks[key]}</b>
                    <Box px={1}>
                      <WarningRoundedIcon fontSize="small" htmlColor="#FC0303" />
                    </Box>
                    <b>{row.itemMarks[key]}</b>
                  </Box>
                </CheckpointTooltip>
              );
            }
            switch (row.marks[key]) {
              case 'A':
                return 'Absent';
              case 'M':
                return 'Missing';
              case null:
                return '';
              default:
                return row.marks[key] as string;
            }
          },
          cellProps: {
            align: 'center' as const,
          },
        })
      ),
      {
        header: 'Hold/Release',
        accessor: 'compliance',
        renderCell: (row: LearnerMark) => (
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={() => confirmHoldOrReleaseModal(row.candidateNumber, !!row.hold)}
          >
            {row.hold === true ? 'Release learner' : 'Hold learner'}
          </Button>
        ),
        cellProps: {
          align: 'center' as const,
        },
      },
    ],
    [tableData]
  );
  const retrievingPageDependencies = useMemo(() => retrievingSessions || retrievingLearnerStats, [
    retrievingSessions,
    retrievingLearnerStats,
  ]);
  // put in here any page deps where you want to error whole page,
  // if instead you want inline error somewhere in the page, handle that separately.
  const pageDependeciesError = useMemo(
    () => errorRetrievingLearnerStats || errorRetrievingSessions,
    [errorRetrievingSessions, errorRetrievingLearnerStats]
  );
  const notFound = useMemo(
    () =>
      id &&
      !retrievingSessions &&
      sessions.length > 0 &&
      sessions.filter((x) => x.id === parseInt(id, 10)).length === 0,
    [sessions, retrievingSessions, id]
  );
  const qual = useMemo(() => getQualification(parseInt(qid, 10)), [qid]);
  if (notFound || !qual) {
    return <NotFound />;
  }
  return (
    <>
      <Box py={1.5} borderBottom={`4px solid ${qual.color}`}>
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center">
            <Box>
              <Typography variant="h4" gutterBottom>
                {cid}
              </Typography>
              <Breadcrumbs aria-label="breadcrumb" gutter="sm">
                <Link component={RouterLink} to={`/session/${id}`}>
                  <span>{currentSession}</span>
                </Link>
                <Link component={RouterLink} to={`/session/${id}/qualification/${qid}`}>
                  <span>{qual.name}</span>
                </Link>
                <Typography>{cid}</Typography>
              </Breadcrumbs>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box>
        {retrievingPageDependencies && <PageLoader />}
        {pageDependeciesError && <Error />}
        {!!assessmentMarks && (
          <Container maxWidth="lg">
            <Box mt="1rem" mb="2rem">
              <Tabs
                value={filter || 0}
                onChange={(
                  _event: React.ChangeEvent<Record<string, unknown>>,
                  newValue: ExtendedFilter
                ) => {
                  history.push(
                    newValue
                      ? `/session/${id}/qualification/${qid}/centre/${cid}?filter=${newValue}`
                      : `/session/${id}/qualification/${qid}/centre/${cid}`
                  );
                }}
                indicatorColor="primary"
                textColor="primary"
                data-testid="filter-tabs"
              >
                {filters.map((item) => (
                  <FilterTab
                    value={item.value}
                    label={
                      <FilterTabLabel
                        label={item.label}
                        primaryCount={item.count}
                        secondaryCount={item.entryCount}
                      />
                    }
                  />
                ))}
              </Tabs>
            </Box>
            <Box display="flex" my="1rem" alignItems="center">
              <Box pt="0.5rem" display="flex" alignItems="center">
                <Box mr="2rem">
                  <Select
                    label="Subject:"
                    options={subjects}
                    onChange={(e) => setSubject(String(e.target.value))}
                    value={subject}
                    data-testid="subject-select"
                  />
                </Box>
                {subject === getQualification(parseInt(qid, 10)).e2l && (
                  <FilterCheckbox
                    boxLabel="Filter learners by:"
                    singleFilterLabel="E prefix"
                    onChange={handleSingleFilter}
                    data-testid="single-filter"
                  />
                )}
              </Box>
              <Box ml="auto">
                <SearchInput
                  value={searchQuery}
                  placeholder="Search for a learner name or number"
                  onChange={handleSearch}
                  onClear={() => history.push(`/session/${id}/qualification/${qid}/centre/${cid}`)}
                />
              </Box>
            </Box>
            {(debouncedQuery || e2lFilterChecked) && tableData?.length === 0 ? (
              <NoResultsView
                title={
                  debouncedQuery
                    ? `No results found for ‘${debouncedQuery}’`
                    : `No results found for E prefix filter`
                }
                message={
                  debouncedQuery
                    ? `We couldn’t find a match for ‘${debouncedQuery}’.
                  Please try another search.`
                    : ''
                }
                view="no-search"
              >
                {debouncedQuery ? (
                  <Button
                    color="primary"
                    size="small"
                    onClick={() =>
                      history.push(`/session/${id}/qualification/${qid}/centre/${cid}`)
                    }
                  >
                    Reset the search
                  </Button>
                ) : (
                  <></>
                )}
              </NoResultsView>
            ) : (
              <>
                <Table<LearnerMark>
                  title={
                    assessmentMarks.find((item) => subject === item.assessmentCode)
                      ?.assessmentName || ''
                  }
                  getRowKey={(item: LearnerMark) => item.candidateNumber.toString()}
                  columns={headerColumns}
                  data={tableData}
                  rowsPerPage={10}
                  data-testid="learners-table"
                />
                {selectedLearner && subject && (
                  <DialogWindow
                    description={`Please confirm which subjects to ${
                      selectedLearnerOnHold ? 'release' : 'hold'
                    } for learner ${selectedLearner}:`}
                    onCloseDialog={handleCloseHoldOrRelease}
                    openDialog={holdOrReleaseModal}
                    title="Holds update"
                    ConfirmButtonOnClick={async () => {
                      await confirmHoldCompleteModal();
                    }}
                    data-testid="DialogConfirmHold"
                    buttonDataTestId="confirmHoldButton"
                  >
                    {selectedLearner && subject ? (
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="subject"
                          name="subject-hold"
                          onChange={(option: ChangeEvent<HTMLInputElement>) => {
                            const input: string[] = option.target.value.split('-');
                            const subjList =
                              input[0] === 'All'
                                ? assessmentMarks
                                    .map((x) => ({ [x.assessmentCode]: input[1] }))
                                    .reduce((res, item) => {
                                      const key = Object.keys(item)[0];
                                      res[key] = item[key];
                                      return res;
                                    })
                                : { [input[0]]: input[1] };
                            setHoldOrReleaseUpdates(subjList);
                          }}
                        >
                          <FormControlLabel
                            value={`${subject}-hold`}
                            control={<Radio color="primary" />}
                            data-testid="holdSubject"
                            label={
                              assessmentMarks.find((item) => subject === item.assessmentCode)
                                ?.assessmentName || ''
                            }
                          />
                          <FormControlLabel
                            value="All-hold"
                            control={<Radio color="primary" />}
                            label="All subjects"
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : (
                      <Loader />
                    )}
                  </DialogWindow>
                )}
                {selectedLearner && (
                  <DialogWindow
                    description={
                      holdTask.result?.data
                        ? `Holds have been updated for learner ${selectedLearner}`
                        : `Updating holds for learner ${selectedLearner}...`
                    }
                    onCloseDialog={handleCloseHoldComplete}
                    openDialog={holdCompleteModal}
                    title="Holds update"
                    ConfirmButtonOnClick={() => {
                      handleCloseHoldComplete();
                    }}
                    data-testid="DialogConfirmHoldComplete"
                    disableButton={!holdTask.result?.data}
                    buttonDataTestId="confirmHoldCompleteButton"
                  >
                    {holdTask.result?.data ? <></> : <Loader />}
                  </DialogWindow>
                )}
              </>
            )}
          </Container>
        )}
      </Box>
    </>
  );
};
