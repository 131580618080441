import React, { useMemo, useEffect } from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { SeriesStats } from '@cambridgeassessment/checkpoint-dtos';
import { SessionQualStatsRow } from '../components/SessionQualStatsRow';
import Select from '../components/Select/Select';
import { PageLoader } from '../components/PageLoader';
import { Error, NotFound } from '../components/NoResultsView/NoResultsView';
import { useSessions } from '../utils/useSessions';

export interface SessionPath {
  id: string;
}

export const SessionOverviewPage: React.FC = (): JSX.Element => {
  const { id } = useParams<SessionPath>();
  const config = useApplicationEnv();
  const history = useHistory();
  const { sessions, retrievingSessions, errorRetrievingSessions } = useSessions();

  const series = useMemo(
    () => sessions.map((item) => ({ value: item.id, label: item.displayName })),
    [sessions]
  );

  const mostRecentSeries = useMemo(() => {
    if (!sessions.length) {
      return undefined;
    }

    const activeSessions = sessions.filter((session) => session.status === 'Active');

    if (activeSessions.length > 1) {
      return activeSessions.reduce((mostCurrent, session) =>
        Math.abs(dayjs(mostCurrent.displayName).diff(dayjs())) >
        Math.abs(dayjs(session.displayName).diff(dayjs()))
          ? session
          : mostCurrent
      ).id;
    }

    if (activeSessions.length === 1) {
      return activeSessions[0].id;
    }
    return sessions[0].id;
  }, [sessions]);

  const setSeries = (sessionId: number): void => history.push(`/session/${sessionId}`);

  useEffect(() => {
    if (!id && mostRecentSeries) {
      setSeries(mostRecentSeries);
    }
  }, [id, mostRecentSeries]);

  const getSeriesStatsMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${id}/stats`,
    };
  }, [config, id]);

  const getSeriesStatsTask = useAsyncTaskAxios<AxiosResponse<SeriesStats[]>>(
    axios,
    getSeriesStatsMemo
  );
  useAsyncRun(config && id && getSeriesStatsTask);

  const notFound = useMemo(
    () =>
      id &&
      !retrievingSessions &&
      sessions.length > 0 &&
      sessions.filter((x) => x.id === parseInt(id, 10)).length === 0,
    [sessions, retrievingSessions, id]
  );
  const retrievingPageDependencies = useMemo(
    () => retrievingSessions || (getSeriesStatsTask.started && getSeriesStatsTask.pending),
    [retrievingSessions, getSeriesStatsTask.started, getSeriesStatsTask.pending]
  );
  const pageDependeciesError = useMemo(() => errorRetrievingSessions || getSeriesStatsTask.error, [
    errorRetrievingSessions,
    getSeriesStatsTask.error,
  ]);

  if (notFound) {
    return <NotFound />;
  }
  return (
    <Box py={1.5}>
      <Container maxWidth="lg">
        {!retrievingSessions && !errorRetrievingSessions && (
          <>
            <Typography variant="h3" gutterBottom>
              Recent series data
            </Typography>
            <Box pb={2}>
              <Select
                label="Series:"
                options={series}
                onChange={(e) => setSeries(e.target.value as number)}
                value={id}
                data-testid="series-select"
              />
            </Box>
          </>
        )}
        {retrievingPageDependencies && <PageLoader />}
        {pageDependeciesError && <Error />}
        {!retrievingPageDependencies && !pageDependeciesError && (
          <Box mx="3.5rem">
            {getSeriesStatsTask.result !== undefined &&
              getSeriesStatsTask.result?.data.map((x) => (
                <SessionQualStatsRow
                  key={x.qualificationId}
                  sessionId={parseInt(id, 10)}
                  stats={x}
                />
              ))}
          </Box>
        )}
      </Container>
    </Box>
  );
};
