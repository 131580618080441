import React from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';

interface FilterCheckboxProps {
  boxLabel: string;
  singleFilterLabel: string;
  onChange: () => void;
  'data-testid'?: string;
}

const FilterCheckbox: React.FC<FilterCheckboxProps> = ({
  boxLabel,
  singleFilterLabel,
  onChange,
  'data-testid': dataTestId,
}) => (
  <Box display="flex" alignItems="center" data-testid={dataTestId}>
    <Box mr="2rem">
      <Typography variant="body1">{boxLabel}</Typography>
    </Box>
    <FormControlLabel
      control={<Checkbox onChange={onChange} color="primary" />}
      label={singleFilterLabel}
    />
  </Box>
);

FilterCheckbox.defaultProps = {
  'data-testid': '',
};

export default FilterCheckbox;
