import React, { FC } from 'react';
import { useAuth } from 'oidc-react';
import { useAxiosInterceptors } from './utils/useAxiosInterceptor';
import { PageLoader } from './components/PageLoader';

const PrivateRoute: FC = ({ children }): JSX.Element => {
  const auth = useAuth();
  const interceptorConfigured = useAxiosInterceptors();

  if (!auth?.userData) {
    return <></>;
  }

  if (!interceptorConfigured) {
    return <PageLoader />;
  }
  return <>{children}</>;
};

export default PrivateRoute;
