import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

export interface PageLoadProps {
  message?: string;
  messageAfterTimeout?: string;
  timeoutSeconds?: number;
}

export const PageLoader: React.FC<PageLoadProps> = ({
  message,
  messageAfterTimeout = 'It may take a few more seconds to finish loading...',
  timeoutSeconds = 10,
}): JSX.Element => {
  const [timedOut, setTimedOut] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimedOut(true);
    }, timeoutSeconds * 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Box
      mt="5rem"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      data-testid="page-loader"
    >
      <CircularProgress />

      <Box my="3rem">
        {!timedOut && message !== undefined && <Typography variant="body1">{message}</Typography>}
        {timedOut && messageAfterTimeout !== undefined && (
          <Typography variant="body1">{messageAfterTimeout}</Typography>
        )}
      </Box>
    </Box>
  );
};
