/* eslint-disable no-nested-ternary */
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Link as RouterLink, useParams, useLocation, useHistory } from 'react-router-dom';
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';
import { useDebounce } from 'use-lodash-debounce';
import WarningRoundedIcon from '@material-ui/icons/Warning';
import { Box, Container, IconButton, Link, Tabs, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Filter,
  CentreHoldStatus,
  CentreStats,
  ReportIssuerStatus,
  ReportGeneratorStatus,
} from '@cambridgeassessment/checkpoint-dtos';
import { getQualification } from '@cambridgeassessment/checkpoint-utils';
import { Breadcrumbs, Button } from '@cambridgeassessment/cambridge-ui';
import CreateIcon from '@material-ui/icons/Create';

// Utils
import { useCentreStats } from '../utils/useCentreStats';
import { useGrading } from '../utils/useGradingStatus';
import { useDownloadReportInfo } from '../utils/useDownloadReportInfo';
import { useSessions } from '../utils/useSessions';
import { usePollStatus } from '../utils/usePollStatus';
import { useResultReleaseDate } from '../utils/useResultReleaseDate';
import { contains } from '../utils/helpers';

// Components
import DatePicker from '../components/DatePicker/DatePicker';
import DialogWindow from '../components/DialogWindow/DialogWindow';
import DownloadS3FileButton from '../components/DownloadS3FileButton';
import FilterCheckbox from '../components/FilterCheckbox/FilterCheckbox';
import { RunInfoLabel } from '../components/RunInfoLabel/RunInfoLabel';
import { GeneratedInfoLabel } from '../components/GeneratedInfoLabel/GeneratedInfoLabel';
import {
  WarningIcon,
  RunTooltipContent,
  CheckpointTooltip,
} from '../components/WarningIcon/WarningIcon';
import { FilterTab } from '../components/Tabs/FilterTab';
import { FilterTabLabel } from '../components/Tabs/FilterTabLabel';
import HoldOptions from '../components/HoldOptions/HoldOptions';
import { LabelWithIndicator } from '../components/LabelWithIndicator';
import Loader from '../components/Loader/Loader';
import MoreActionMenu from '../components/MoreActionMenu/MoreActionMenu';
import NoResultsView, { Error, NotFound } from '../components/NoResultsView/NoResultsView';
import { PageLoader } from '../components/PageLoader';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import { ReportViewerModal } from '../components/ReportViewer/ReportViewerModal';
import SearchInput from '../components/SearchInput/SearchInput';
import Table from '../components/Table/Table';
import WarningMessage from '../components/WarningMessage/WarningMessage';

// Icons
import { ReactComponent as IconPdf } from '../icons/iconPdf.svg';
import { ReactComponent as IconXls } from '../icons/iconXls.svg';
import ErrorMessage from '../components/ErrorMessage/ErrorMessage';

export interface SessionQualPath {
  id: string;
  qid: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    dateInputContainer: {
      gridTemplateColumns: 'auto auto',
      gridTemplateCOlumns: 'auto',
      gridColumnGap: '.5em',
    },
    editButton: {
      padding: '8px',
    },
    warning: {
      justifySelf: 'end',
    },
  })
);

const getFilterLabel = (filter?: Filter): string => {
  if (filter === 'complete') {
    return 'Complete';
  }
  if (filter === 'incomplete') {
    return 'Incomplete';
  }
  if (filter === 'absent') {
    return 'With Absents';
  }
  if (filter === 'missing') {
    return 'Missing';
  }
  if (filter === 'held') {
    return 'Held';
  }
  if (filter === 'inError') {
    return 'Error';
  }
  if (filter === 'outdated') {
    return 'Outdated';
  }

  return 'All Centres';
};

export const SessionQualificationProgressPage: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const config = useApplicationEnv();
  const { id, qid } = useParams<SessionQualPath>();
  const location = useLocation();
  const history = useHistory();
  const [openConfirmOneCentreModal, setConfirmOneCentreOpen] = useState(false);
  const [holdOrReleaseModal, setHoldOrReleaseModal] = useState(false);
  const [holdOrReleaseUpdates, setHoldOrReleaseUpdates] = useState<Record<string, string>>({});
  const [selectedCentre, setSelectedCentre] = useState<string | undefined>(undefined);
  const [holdCompleteModal, setHoldCompleteModal] = useState(false);
  const [dateInputDisabled, setDateDisabled] = useState(true);
  const [e2lFilterChecked, setE2lFilterChecked] = useState(false);
  const { sessions, retrievingSessions, errorRetrievingSessions } = useSessions();
  const {
    retrievingReleaseDate,
    errorRetrievingReleaseDate,
    errorSettingReleaseDate,
    releaseDate,
    setReleaseDate,
  } = useResultReleaseDate(parseInt(id, 10), parseInt(qid, 10));
  const [issueReportModal, setIssueReportModal] = useState(false);
  const [isIssueReportsOpen, setIssueReportsConfirmation] = useState(false);

  const useQuery = (): URLSearchParams => new URLSearchParams(location.search);

  const query = useQuery();

  const generateReportMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${id}/qualifications/${qid}/generateReport`,
      method: 'POST',
    };
  }, [config]);

  const generateReportTask = useAsyncTaskAxios<
    AxiosResponse<{ executionArn: string; startDate: string; executionName: string }>
  >(axios, generateReportMemo);

  const generateDownloadReportMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${id}/qualifications/${qid}/generateReportQueue`,
      method: 'POST',
    };
  }, [config]);

  const generateDownloadReportTask = useAsyncTaskAxios<
    AxiosResponse<{ executionArn: string; startDate: string; executionName: string }>
  >(axios, generateDownloadReportMemo);

  const handleReleaseDateChange = useCallback((date: Date) => {
    setReleaseDate(date);
  }, []);

  const issueReportMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${id}/qualifications/${qid}/issueReport`,
      method: 'POST',
    };
  }, [config]);

  const issueReportTask = useAsyncTaskAxios<
    AxiosResponse<{ executionArn: string; startDate: string }>
  >(axios, issueReportMemo);

  const { firstPollStatusBack, lastReportStatus: lastReportGeneratorStatus } = usePollStatus(
    parseInt(id, 10),
    parseInt(qid, 10),
    'reportGeneratorStatus',
    generateReportTask.result?.data.executionName
  ) as { firstPollStatusBack: boolean; lastReportStatus: ReportGeneratorStatus };

  const {
    firstPollStatusBack: firstIssuerPollStatusBack,
    lastReportStatus: lastReportIssuerStatus,
  } = usePollStatus(
    parseInt(id, 10),
    parseInt(qid, 10),
    'reportIssuerStatus',
    issueReportTask.result?.data.executionArn
  ) as { firstPollStatusBack: boolean; lastReportStatus: ReportIssuerStatus };

  const latestRelevantGenerationStatus = useMemo(() => {
    if (
      generateReportTask.started &&
      lastReportGeneratorStatus &&
      ((generateReportTask.result &&
        lastReportGeneratorStatus.runAt < generateReportTask.result.data.startDate) ||
        !generateReportTask.result)
    ) {
      return undefined;
    }
    return lastReportGeneratorStatus;
  }, [generateReportTask.started, generateReportTask.result, lastReportGeneratorStatus]);

  const reportGeneratorRunning = useMemo(() => {
    if (generateReportTask.started && generateReportTask.pending) {
      return true;
    }
    if (
      (latestRelevantGenerationStatus?.sfStatus === 'COMPLETE' ||
        latestRelevantGenerationStatus?.sfStatus === 'FAILED') &&
      latestRelevantGenerationStatus?.executionId === generateReportTask.result?.data.executionArn
    ) {
      return false;
    }
    if (!latestRelevantGenerationStatus && generateReportTask.result?.data.executionArn) {
      return true;
    }
    return (
      latestRelevantGenerationStatus?.sfStatus === 'STARTED' ||
      (generateReportTask.started && generateReportTask.pending) ||
      (generateReportTask.result &&
        lastReportGeneratorStatus?.executionId === generateReportTask.result?.data.executionArn)
    );
  }, [
    latestRelevantGenerationStatus,
    generateReportTask.started,
    generateReportTask.result,
    generateReportTask.pending,
  ]);

  const latestRelevantIssuerStatus = useMemo(() => {
    if (
      issueReportTask.started &&
      lastReportIssuerStatus &&
      ((issueReportTask.result &&
        lastReportIssuerStatus.runAt < issueReportTask.result.data.startDate) ||
        !issueReportTask.result)
    ) {
      return undefined;
    }
    return lastReportIssuerStatus;
  }, [issueReportTask.started, lastReportIssuerStatus, issueReportTask.result]);

  const reportIssuerRunning = useMemo(() => {
    if (issueReportTask.started && issueReportTask.pending) {
      return true;
    }
    if (
      (latestRelevantIssuerStatus?.sfStatus === 'COMPLETE' ||
        latestRelevantIssuerStatus?.sfStatus === 'FAILED') &&
      latestRelevantIssuerStatus?.executionId === issueReportTask.result?.data.executionArn
    ) {
      return false;
    }
    if (!latestRelevantIssuerStatus && issueReportTask.result?.data.executionArn) {
      return true;
    }
    return (
      latestRelevantIssuerStatus?.sfStatus === 'STARTED' ||
      (issueReportTask.started && issueReportTask.pending) ||
      (issueReportTask.result &&
        lastReportGeneratorStatus?.executionId === issueReportTask.result?.data.executionArn)
    );
  }, [
    latestRelevantIssuerStatus,
    issueReportTask.started,
    issueReportTask.result,
    issueReportTask.pending,
  ]);

  const filter = useMemo<Filter | undefined>(() => {
    const filterQuery = query.get('filter');

    if (!filterQuery) {
      return undefined;
    }

    if (Array.isArray(filterQuery)) {
      return filterQuery[0] as Filter;
    }

    return filterQuery as Filter;
  }, [location]);

  const {
    filtered,
    retrievingCentreStats,
    errorRetrievingCentreStats,
    totalCount,
    totalEntryCount,
    centreSummaryStats,
  } = useCentreStats({
    sessionId: parseInt(id, 10),
    qualificationId: parseInt(qid, 10),
    filter,
  });

  const { gradingAvailable, retrievingGradingData, errorRetrievingGradingData } = useGrading({
    sessionId: parseInt(id, 10),
    qualificationId: parseInt(qid, 10),
  });

  const { downloadReportInfoAvailable, downloadReportInfoData } = useDownloadReportInfo({
    sessionId: parseInt(id, 10),
    qualificationId: parseInt(qid, 10),
  });

  const searchQuery = query.get('search') || '';
  const debouncedQuery = useDebounce(searchQuery, 300);
  const [selectedKey, setSelectedKey] = useState<
    { key: string; fileName: string; version?: string } | undefined
  >(undefined);
  const [showViewer, setShowViewer] = useState(false);
  const reportFilePrefix = (centreNumber: string): string => `${id}-${qid}-${centreNumber}`;
  const showItem = (centreNumber: string, key: string, version?: string): void => {
    setSelectedKey({ key, version, fileName: `${reportFilePrefix(centreNumber)}.pdf` });
    setShowViewer(true);
  };

  const toggle = (): void => {
    setShowViewer(!showViewer);
  };

  const getCentreHoldMemo = useMemo(() => {
    if (!config || !selectedCentre || !holdOrReleaseModal) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${id}/qualifications/${qid}/centres/${selectedCentre}/getholdstatus`,
    };
  }, [config, selectedCentre, holdOrReleaseModal]);

  const getCentreHoldTask = useAsyncTaskAxios<AxiosResponse<CentreHoldStatus[]>>(
    axios,
    getCentreHoldMemo
  );

  const holdMemo = useMemo(() => {
    if (!config || !selectedCentre) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${id}/qualifications/${qid}/centres/${selectedCentre}/holdlearner`,
      method: 'POST',
    };
  }, [config, selectedCentre]);

  const holdTask = useAsyncTaskAxios<AxiosResponse<string>>(axios, holdMemo);

  const confirmOneCentreModal = (centreId: string): void => {
    setSelectedCentre(centreId);
    setConfirmOneCentreOpen(true);
  };

  const handleCloseOneCentre = (): void => {
    setConfirmOneCentreOpen(false);
  };

  const confirmIssueReportModal = (centreId: string): void => {
    setSelectedCentre(centreId);
    setIssueReportModal(true);
  };

  const handleCloseIssueReport = (): void => {
    setIssueReportModal(false);
  };

  const confirmHoldCompleteModal = async (): Promise<void> => {
    setHoldOrReleaseModal(false);
    setHoldCompleteModal(true);
    await holdTask.start({
      data: {
        assessmentHold: Object.keys(holdOrReleaseUpdates).map((key: string) => ({
          assessmentCode: key,
          hold: holdOrReleaseUpdates[key] === 'hold',
        })),
      },
    });
  };

  const handleCloseHoldComplete = (): void => {
    setHoldOrReleaseUpdates({});
    setHoldCompleteModal(false);
  };

  const confirmHoldOrReleaseModal = (centreId: string): void => {
    setSelectedCentre(centreId);
    setHoldOrReleaseModal(true);
  };

  const handleCloseHoldOrRelease = (): void => {
    setHoldOrReleaseUpdates({});
    setHoldOrReleaseModal(false);
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = (): void => {
    setOpen(false);
  };
  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const [downloadReportOpen, downloadReportSetOpen] = React.useState(false);
  const downloadReportHandleClose = (): void => {
    downloadReportSetOpen(false);
  };
  const downloadReportHandleClickOpen = (): void => {
    downloadReportSetOpen(true);
  };

  const tableData = useMemo(
    () =>
      filtered &&
      filtered.filter(
        (item) =>
          (contains(item.name, debouncedQuery) || contains(item.id, debouncedQuery)) &&
          (e2lFilterChecked ? item.hasTeachingGroupEPrefix : true)
      ),
    [
      filtered,
      debouncedQuery,
      reportGeneratorRunning,
      reportIssuerRunning,
      gradingAvailable,
      e2lFilterChecked,
    ]
  );

  const filterLabel = useMemo(() => getFilterLabel(filter), [filter]);

  const currentSession = useMemo(
    () => sessions.find((session) => String(session.id) === id)?.displayName,
    [id, sessions]
  );

  const handleSearch = (value: string): void => {
    const url = `/session/${id}/qualification/${qid}`;
    if (value) {
      history.push(`${url}?search=${value}`);
    } else {
      history.push(`${url}`);
    }
  };

  const handleSingleFilter = (): void => setE2lFilterChecked(!e2lFilterChecked);

  const toggleDateInput = (): void => setDateDisabled(!dateInputDisabled);

  useAsyncRun(getCentreHoldTask);

  const issueReportConfirmationMsg = useMemo(() => {
    const selected = tableData?.find((item) => item.id === selectedCentre);
    if (selected && selected.incompleteCount > 0) {
      return 'This centre contains incomplete entries, please confirm you wish to proceed';
    }
    if (selected && selected.hasHeldLearners) {
      return 'This centre contains held entries, please confirm you wish to proceed';
    }
    return 'Please confirm you wish to proceed';
  }, [selectedCentre, tableData]);

  const retrievingPageDependencies = useMemo(
    () =>
      retrievingSessions ||
      retrievingGradingData ||
      !firstPollStatusBack ||
      !firstIssuerPollStatusBack ||
      retrievingReleaseDate,
    [
      retrievingSessions,
      firstPollStatusBack,
      firstIssuerPollStatusBack,
      retrievingReleaseDate,
      retrievingGradingData,
    ]
  );

  // put in here any page deps where you want to error whole page,
  // if instead you want inline error somewhere in the page, handle that separately.
  const pageDependeciesError = useMemo(() => errorRetrievingSessions !== null, [
    errorRetrievingSessions,
  ]);

  const headerColumns = useMemo(
    () => [
      {
        header: 'Centre name',
        accessor: 'name',
        renderCell: (row: CentreStats) => (
          <Typography variant="subtitle2">
            <Link
              color="primary"
              component={RouterLink}
              to={`/session/${id}/qualification/${qid}/centre/${String(row.id)}`}
            >
              {row.name}
            </Link>
          </Typography>
        ),
      },
      {
        header: 'Centre #',
        accessor: 'id',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Entries',
        accessor: 'entryCount',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Marks',
        accessor: 'completeCount',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Absent',
        accessor: 'absentCount',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Missing',
        accessor: 'missingCount',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Holds',
        accessor: 'hasHeldLearners',
        renderCell: (row: CentreStats) => (
          <>
            {row.hasHeldLearners && <LabelWithIndicator label="Learners withheld" />}
            {!row.hasHeldLearners && (
              <Typography variant="body1" color="textSecondary">
                Not withheld
              </Typography>
            )}
          </>
        ),
      },
      {
        header: 'Generated report',
        accessor: 'generated',
        sortFunction: (row: CentreStats) => {
          if (row.latestGeneratedError) {
            return new Date(row.latestGeneratedError?.generatedRunInfo.runAt).getTime();
          }
          if (row.latestGenerated?.generatedRunInfo.runAt) {
            return new Date(row.latestGenerated?.generatedRunInfo.runAt).getTime();
          }
          return 0;
        },
        renderCell: (row: CentreStats) =>
          row.latestGenerated || row.latestGeneratedError ? (
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <CheckpointTooltip
                title={
                  row.latestGenerated ? (
                    <RunTooltipContent generationRunInfo={row.latestGenerated?.generatedRunInfo} />
                  ) : (
                    ''
                  )
                }
                arrow
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  data-testid="icons-box"
                >
                  {row.latestGenerated && row.latestGenerated.pdfReportInfo?.s3Key !== undefined && (
                    <IconButton
                      onClick={() =>
                        showItem(
                          row.id,
                          row.latestGenerated?.pdfReportInfo?.s3Key || '',
                          row.latestGenerated?.pdfReportInfo?.versionId
                        )
                      }
                    >
                      <IconPdf />
                    </IconButton>
                  )}
                  {row.latestGenerated && row.latestGenerated.xlsxReportInfo?.s3Key !== undefined && (
                    <DownloadS3FileButton
                      s3ObjectVerion={row.latestGenerated?.xlsxReportInfo.versionId}
                      s3key={row.latestGenerated?.xlsxReportInfo?.s3Key}
                      downloadFileName={`${reportFilePrefix(row.id)}.xlsx`}
                    >
                      <IconXls />
                    </DownloadS3FileButton>
                  )}
                </Box>
              </CheckpointTooltip>
              {row.latestGeneratedError && row.latestGeneratedError.generatedRunInfo.error && (
                <WarningIcon generationRunInfo={row.latestGeneratedError.generatedRunInfo} />
              )}
            </Box>
          ) : (
            'No data'
          ),
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Issued report',
        accessor: 'issued',
        renderCell: (row: CentreStats) =>
          row.latestIssued || row.latestIssuedError ? (
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <CheckpointTooltip
                title={
                  row.latestIssued && row.latestIssued.issuedRunInfo ? (
                    <RunTooltipContent
                      generationRunInfo={row.latestIssued?.generatedRunInfo}
                      issueRunInfo={row.latestIssued?.issuedRunInfo}
                    />
                  ) : (
                    ''
                  )
                }
                arrow
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  data-testid="icons-box"
                >
                  {row.latestIssued && row.latestIssued.pdfReportInfo?.s3Key !== undefined && (
                    <IconButton
                      onClick={() =>
                        showItem(
                          row.id,
                          row.latestIssued?.pdfReportInfo?.s3Key || '',
                          row.latestIssued?.pdfReportInfo?.versionId
                        )
                      }
                    >
                      <IconPdf />
                    </IconButton>
                  )}
                  {row.latestIssued && row.latestIssued.xlsxReportInfo?.s3Key !== undefined && (
                    <DownloadS3FileButton
                      s3ObjectVerion={row.latestIssued?.xlsxReportInfo.versionId}
                      s3key={row.latestIssued?.xlsxReportInfo?.s3Key}
                      downloadFileName={`${reportFilePrefix(row.id)}.xlsx`}
                    >
                      <IconXls />
                    </DownloadS3FileButton>
                  )}
                </Box>
              </CheckpointTooltip>
              {row.latestIssuedError &&
                row.latestIssuedError.issuedRunInfo &&
                row.latestIssuedError.issuedRunInfo.error && (
                  <WarningIcon issueRunInfo={row.latestIssuedError.issuedRunInfo} />
                )}
              {row.latestIssued?.generatedRunInfo?.executionId !==
                row.latestGenerated?.generatedRunInfo.executionId && (
                <CheckpointTooltip title="Issued Report is older than latest generated report">
                  <WarningRoundedIcon fontSize="small" htmlColor="#FCC652" />
                </CheckpointTooltip>
              )}
            </Box>
          ) : (
            'No data'
          ),
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Actions',
        accessor: 'actions',
        renderCell: (row: CentreStats) => (
          <MoreActionMenu
            disableGenerateReport={reportGeneratorRunning || !gradingAvailable}
            disableIssueReport={
              !!reportIssuerRunning ||
              !row.latestGenerated ||
              row.latestIssued?.pdfReportInfo?.versionId ===
                row.latestGenerated?.pdfReportInfo?.versionId
            }
            onGenerateReport={() => {
              confirmOneCentreModal(row.id);
            }}
            onHoldOrRelease={() => {
              confirmHoldOrReleaseModal(row.id);
            }}
            onIssueReport={() => {
              confirmIssueReportModal(row.id);
            }}
          />
        ),
      },
    ],
    [reportGeneratorRunning, reportIssuerRunning, gradingAvailable]
  );

  const notFound = useMemo(
    () =>
      id &&
      !retrievingSessions &&
      sessions.length > 0 &&
      sessions.filter((x) => x.id === parseInt(id, 10)).length === 0,
    [sessions, retrievingSessions, id]
  );

  const qual = useMemo(() => getQualification(parseInt(qid, 10)), [qid]);
  if (notFound || !qual) {
    return <NotFound />;
  }

  if (retrievingPageDependencies) {
    return <PageLoader />;
  }

  if (pageDependeciesError) {
    return <Error />;
  }

  return (
    <>
      <Box py={1.5} borderBottom={`4px solid ${qual.color}`}>
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center">
            <Box>
              <Typography variant="h4" gutterBottom>
                Centres
              </Typography>
              <Breadcrumbs aria-label="breadcrumb" gutter="sm">
                <Link component={RouterLink} to={`/session/${id}`}>
                  <span>{currentSession}</span>
                </Link>
                <Typography>{qual.name}</Typography>
              </Breadcrumbs>
            </Box>
            <Box ml="auto" display="flex">
              <Box mr={2}>
                <Box display="grid" className={classes.dateInputContainer}>
                  <DatePicker
                    label="Results release date"
                    onChange={handleReleaseDateChange}
                    minDate={new Date()}
                    disabled={dateInputDisabled}
                    date={releaseDate}
                  />
                  <IconButton onClick={toggleDateInput} className={classes.editButton}>
                    <CreateIcon fontSize="small" />
                  </IconButton>
                  <Box display="flex" flexDirection="column" justifyContent="flex-end">
                    {errorSettingReleaseDate && (
                      <div className={classes.warning}>
                        <WarningMessage isError message="Could not set release date" />
                      </div>
                    )}
                    {errorRetrievingReleaseDate && (
                      <div className={classes.warning}>
                        <WarningMessage isError message="Could not retrieve release date" />
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box display="inline-block" mr={2}>
                {reportGeneratorRunning && (
                  <ProgressBar
                    errorCount={latestRelevantGenerationStatus?.errorCount || 0}
                    increment={
                      (latestRelevantGenerationStatus?.centreProgress || 0) +
                      (latestRelevantGenerationStatus?.errorCount || 0)
                    }
                    label="Generating reports..."
                    subLabel="generated"
                    total={latestRelevantGenerationStatus?.centreTotal}
                  />
                )}
                {!reportGeneratorRunning && (
                  <Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={handleClickOpen}
                      data-testid="buttonGenerateReport"
                      disabled={!gradingAvailable}
                    >
                      Generate Series Reports
                    </Button>
                    {lastReportGeneratorStatus && (
                      <RunInfoLabel status={lastReportGeneratorStatus} />
                    )}
                  </Box>
                )}
                {errorRetrievingGradingData && (
                  <WarningMessage message="Error retrieving grading data" />
                )}
                {!gradingAvailable && !retrievingGradingData && !errorRetrievingGradingData && (
                  <WarningMessage message="Grading Data not yet available" />
                )}
              </Box>
              <Box display="inline-block">
                {reportIssuerRunning && (
                  <ProgressBar
                    increment={
                      (latestRelevantIssuerStatus?.centreProgress || 0) +
                      (latestRelevantIssuerStatus?.errorCount || 0)
                    }
                    label="Issuing reports..."
                    subLabel="issued"
                    total={latestRelevantIssuerStatus?.centreTotal}
                  />
                )}
                {!reportIssuerRunning && (
                  <Box>
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => setIssueReportsConfirmation(true)}
                      data-testid="buttonIssueReports"
                      disabled={!lastReportGeneratorStatus}
                    >
                      Issue Series Reports
                    </Button>
                    {lastReportIssuerStatus && (
                      <RunInfoLabel
                        status={{
                          ...lastReportIssuerStatus,
                          input: {
                            centres: lastReportIssuerStatus?.input?.centreId
                              ? [lastReportIssuerStatus.input.centreId]
                              : undefined,
                          },
                        }}
                      />
                    )}
                  </Box>
                )}
              </Box>
              <DialogWindow
                description="You are about to generate reports for all relevant centres in this series
                      within this qualification. This process is time consuming, the reports will
                      appear one by one once they are generated."
                onCloseDialog={handleClose}
                openDialog={open}
                title="Generate Series Reports"
                ConfirmButtonOnClick={async () => {
                  await generateReportTask.start();
                  if (!generateReportTask.error) {
                    handleClose();
                  }
                }}
                data-testid="DialogConfirmAllCentres"
                buttonDataTestId={generateReportTask.result?.data.executionArn}
                fixedWidth
              >
                {generateReportTask.error ? (
                  <ErrorMessage message="Report generation failed. Please try again. If this problem persists, please contact BTS helpdesk" />
                ) : (
                  <></>
                )}
              </DialogWindow>
              <DialogWindow
                description="You are about to issue reports for all relevant centres in this series
                      within this qualification. This process is time consuming, the reports will
                      appear one by one once they are issued."
                onCloseDialog={() => setIssueReportsConfirmation(false)}
                openDialog={isIssueReportsOpen}
                title="Generate Series Reports"
                ConfirmButtonOnClick={async () => {
                  setIssueReportsConfirmation(false);
                  await issueReportTask.start();
                }}
                data-testid="DialogConfirmIssueAllCentres"
                fixedWidth
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box>
        <Container maxWidth="lg">
          {retrievingCentreStats && <PageLoader />}
          {!retrievingCentreStats && errorRetrievingCentreStats && <Error />}
          {!retrievingCentreStats && !errorRetrievingCentreStats && (
            <>
              {filtered !== undefined && totalCount !== undefined && totalCount > 0 && (
                <Box mt="1rem" mb="1rem" display="flex" justifyContent="space-between">
                  <Tabs
                    value={filter || 0}
                    onChange={(
                      _event: React.ChangeEvent<Record<string, unknown>>,
                      newValue: Filter
                    ) => {
                      history.push(
                        newValue
                          ? `/session/${id}/qualification/${qid}?filter=${newValue}`
                          : `/session/${id}/qualification/${qid}`
                      );
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    data-testid="filter-tabs"
                  >
                    {totalCount && totalEntryCount && (
                      <FilterTab
                        value={0}
                        label={
                          <FilterTabLabel
                            label={getFilterLabel()}
                            primaryCount={totalCount}
                            secondaryCount={totalEntryCount}
                          />
                        }
                      />
                    )}
                    {centreSummaryStats && centreSummaryStats.complete && (
                      <FilterTab
                        value="complete"
                        label={
                          <FilterTabLabel
                            label={getFilterLabel('complete')}
                            primaryCount={centreSummaryStats.complete.centreCount}
                            secondaryCount={centreSummaryStats.complete.entryCount}
                          />
                        }
                      />
                    )}
                    {centreSummaryStats && centreSummaryStats.incomplete && (
                      <FilterTab
                        value="incomplete"
                        label={
                          <FilterTabLabel
                            label={getFilterLabel('incomplete')}
                            primaryCount={centreSummaryStats.incomplete.centreCount}
                            secondaryCount={centreSummaryStats.incomplete.entryCount}
                          />
                        }
                      />
                    )}
                    {centreSummaryStats && centreSummaryStats.absent && (
                      <FilterTab
                        value="absent"
                        label={
                          <FilterTabLabel
                            label={getFilterLabel('absent')}
                            primaryCount={centreSummaryStats.absent.centreCount}
                            secondaryCount={centreSummaryStats.absent.entryCount}
                          />
                        }
                      />
                    )}
                    {centreSummaryStats && centreSummaryStats.missing && (
                      <FilterTab
                        value="missing"
                        label={
                          <FilterTabLabel
                            label={getFilterLabel('missing')}
                            primaryCount={centreSummaryStats.missing.centreCount}
                            secondaryCount={centreSummaryStats.missing.entryCount}
                          />
                        }
                      />
                    )}
                    {centreSummaryStats && centreSummaryStats.held && (
                      <FilterTab
                        value="held"
                        label={
                          <FilterTabLabel
                            label={getFilterLabel('held')}
                            primaryCount={centreSummaryStats.held.centreCount}
                          />
                        }
                      />
                    )}
                    {centreSummaryStats && centreSummaryStats.inError && (
                      <FilterTab
                        value="inError"
                        label={
                          <FilterTabLabel
                            label={getFilterLabel('inError')}
                            primaryCount={centreSummaryStats.inError.centreCount}
                          />
                        }
                      />
                    )}
                    {centreSummaryStats && centreSummaryStats.outdated && (
                      <FilterTab
                        value="outdated"
                        label={
                          <FilterTabLabel
                            label={getFilterLabel('outdated')}
                            primaryCount={centreSummaryStats.outdated.centreCount}
                          />
                        }
                      />
                    )}
                  </Tabs>

                  <Box>
                    <Box display="flex" alignItems="center">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={downloadReportHandleClickOpen}
                      >
                        Generate QC report
                      </Button>
                      {downloadReportInfoAvailable && (
                        <Box>
                          <DownloadS3FileButton
                            s3key={`${id}/${qid}/${id}-${qid}.xlsx`}
                            downloadFileName={`${id}-${qid}.xlsx`}
                            downloadReportBucket
                          >
                            <IconXls />
                          </DownloadS3FileButton>
                        </Box>
                      )}
                    </Box>
                    <GeneratedInfoLabel runInfo={downloadReportInfoData} />
                  </Box>
                </Box>
              )}
              {filtered !== undefined && filtered.length === 0 && (
                <NoResultsView
                  view="no-results"
                  title={filter ? `No ${filter} entries found` : 'No entries found'}
                  message={
                    filter
                      ? 'No entries found that match the filter'
                      : 'We could not find any entries for this series and qualification'
                  }
                />
              )}
              {filtered !== undefined && filtered.length > 0 && (
                <>
                  <Box display="flex" my="1rem">
                    <FilterCheckbox
                      boxLabel="Filter centres by:"
                      singleFilterLabel="E prefix"
                      onChange={handleSingleFilter}
                      data-testid="single-filter"
                    />
                    <Box ml="auto">
                      <SearchInput
                        value={searchQuery}
                        placeholder="Search for a centre name or number"
                        onChange={handleSearch}
                        onClear={() => history.push(`/session/${id}/qualification/${qid}`)}
                      />
                    </Box>
                  </Box>
                  {(debouncedQuery || e2lFilterChecked) && tableData?.length === 0 ? (
                    <NoResultsView
                      title={
                        debouncedQuery
                          ? `No results found for ‘${debouncedQuery}’`
                          : `No results found for E prefix filter`
                      }
                      message={
                        debouncedQuery
                          ? `We couldn’t find a match for ‘${debouncedQuery}’.
                  Please try another search.`
                          : ''
                      }
                      view="no-search"
                    >
                      {debouncedQuery ? (
                        <Button
                          color="primary"
                          size="small"
                          onClick={() => history.push(`/session/${id}/qualification/${qid}`)}
                        >
                          Reset the search
                        </Button>
                      ) : (
                        <></>
                      )}
                    </NoResultsView>
                  ) : (
                    <>
                      <Table<CentreStats>
                        title={filterLabel}
                        columns={headerColumns}
                        getRowKey={(item: CentreStats) => item.id}
                        data={tableData}
                        rowsPerPage={10}
                        data-testid="marks-table"
                      />
                      {selectedKey && (
                        <ReportViewerModal
                          downloadFileName={selectedKey.fileName}
                          reportKey={selectedKey.key}
                          reportVersion={selectedKey.version}
                          isOpen={showViewer}
                          toggle={toggle}
                        />
                      )}
                      {selectedCentre && (
                        <DialogWindow
                          description="Please confirm you wish to run centre reports"
                          onCloseDialog={handleCloseOneCentre}
                          openDialog={openConfirmOneCentreModal}
                          title="Confirm"
                          ConfirmButtonOnClick={async () => {
                            await generateReportTask.start({ data: { centres: [selectedCentre] } });
                            if (!generateReportTask.error) {
                              handleCloseOneCentre();
                            }
                          }}
                          fixedWidth
                          data-testid="DialogConfirmOneCentre"
                          buttonDataTestId={generateReportTask.result?.data.executionArn}
                        >
                          {generateReportTask.error ? (
                            <ErrorMessage message="Report generation failed. Please try again. If this problem persists, please contact BTS helpdesk" />
                          ) : (
                            <></>
                          )}
                        </DialogWindow>
                      )}
                      {selectedCentre && (
                        <DialogWindow
                          description={`Please confirm which subjects to be on hold or released for centre ${selectedCentre}:`}
                          onCloseDialog={handleCloseHoldOrRelease}
                          openDialog={holdOrReleaseModal}
                          title="Holds update"
                          ConfirmButtonOnClick={async () => {
                            await confirmHoldCompleteModal();
                          }}
                          data-testid="DialogConfirmHold"
                          disableButton={!Object.keys(holdOrReleaseUpdates).length}
                          buttonDataTestId="confirmHoldButton"
                        >
                          {getCentreHoldTask.result?.data ? (
                            <HoldOptions
                              assessmentsHold={getCentreHoldTask.result?.data.map((assessment) => ({
                                assessmentCode: assessment.assessmentCode,
                                assessmentName: assessment.assessmentName,
                                held: assessment.isWholeSubjectOnHold
                                  ? 'All'
                                  : assessment.heldLearners?.length,
                              }))}
                              onClick={(option: ChangeEvent<HTMLInputElement>) => {
                                const input: string[] = option.target.value.split('-');
                                setHoldOrReleaseUpdates({
                                  ...holdOrReleaseUpdates,
                                  [input[0]]: input[1],
                                });
                              }}
                              selectedValues={holdOrReleaseUpdates}
                            />
                          ) : getCentreHoldTask.error ? (
                            <Error />
                          ) : (
                            <Loader />
                          )}
                        </DialogWindow>
                      )}
                      {selectedCentre && (
                        <DialogWindow
                          description={
                            holdTask.result?.data
                              ? `Holds have been updated for centre ${selectedCentre}`
                              : `Updating holds for centre ${selectedCentre}...`
                          }
                          onCloseDialog={handleCloseHoldComplete}
                          openDialog={holdCompleteModal}
                          title="Holds update"
                          ConfirmButtonOnClick={() => {
                            handleCloseHoldComplete();
                          }}
                          data-testid="DialogConfirmHoldComplete"
                          disableButton={!holdTask.result?.data}
                          buttonDataTestId="confirmHoldCompleteButton"
                        >
                          {holdTask.result?.data ? <></> : holdTask.error ? <Error /> : <Loader />}
                        </DialogWindow>
                      )}
                    </>
                  )}
                  {selectedCentre && (
                    <DialogWindow
                      description={issueReportConfirmationMsg}
                      onCloseDialog={handleCloseIssueReport}
                      openDialog={issueReportModal}
                      title="Confirm"
                      ConfirmButtonOnClick={async () => {
                        handleCloseIssueReport();
                        await issueReportTask.start({ data: { centreId: selectedCentre } });
                      }}
                      data-testid="DialogConfirmIssueOneCentre"
                      buttonDataTestId="confirmIssueReportButton"
                    />
                  )}
                  <DialogWindow
                    description="This process can take several minutes. The latest report will appear when ready"
                    onCloseDialog={downloadReportHandleClose}
                    openDialog={downloadReportOpen}
                    title="Confirm"
                    ConfirmButtonOnClick={async () => {
                      await generateDownloadReportTask.start();
                      if (!generateDownloadReportTask.error) {
                        downloadReportHandleClose();
                      }
                    }}
                    fixedWidth
                    data-testid="DialogDownloadReport"
                  >
                    {generateDownloadReportTask.error ? (
                      <ErrorMessage message="The process failed. If this problem persists, please contact BTS helpdesk" />
                    ) : (
                      <></>
                    )}
                  </DialogWindow>
                </>
              )}
            </>
          )}
        </Container>
      </Box>
    </>
  );
};
