import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { SeriesStats, CentreStatsSummary } from '@cambridgeassessment/checkpoint-dtos';
import { colours } from '@cambridgeassessment/cambridge-ui';
import { getQualification } from '@cambridgeassessment/checkpoint-utils';
import { SessionQualStat } from './SessionQualStat';

export interface SessionQualStatsRowProps {
  sessionId: number;
  stats: SeriesStats;
}
const emptyCount: CentreStatsSummary = {
  centreCount: 0,
  entryCount: 0,
};

export const SessionQualStatsRow: React.FC<SessionQualStatsRowProps> = ({
  stats,
  sessionId,
}): JSX.Element => (
  <>
    <Typography variant="body2" color="inherit" gutterBottom>
      {getQualification(stats.qualificationId).name}
    </Typography>
    <Box mb={2.5}>
      <Box height="8px" bgcolor={getQualification(stats.qualificationId).color} />
      <Box
        bgcolor={colours.white}
        color={colours.monochromeDark}
        paddingX="1.5rem"
        paddingY={stats.total.entryCount === 0 ? '1.5rem' : '1rem'}
        borderLeft={`1px solid ${colours.monochromeLight}`}
        borderRight={`1px solid ${colours.monochromeLight}`}
        borderBottom={`1px solid ${colours.monochromeLight}`}
        borderTop="none"
        borderRadius="0 0 4px 4px"
        display="flex"
        alignItems={stats.total.entryCount === 0 ? 'centre' : 'flex-start'}
        justifyContent={stats.total.entryCount === 0 ? 'centre' : 'flex-start'}
        flexDirection={stats.total.entryCount === 0 ? 'column' : 'row'}
      >
        {stats.total.centreCount === 0 && (
          <>
            <Typography variant="h3" align="center" color="textSecondary" gutterBottom>
              No entries made
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
              There are currently no active entries for this qualification and series
            </Typography>
          </>
        )}
        {stats.total.centreCount > 0 && (
          <>
            <SessionQualStat
              displayLabel="All Centres"
              sessionId={sessionId}
              qualificationId={stats.qualificationId}
              stats={stats.total}
            />
            <SessionQualStat
              displayLabel="Complete"
              sessionId={sessionId}
              qualificationId={stats.qualificationId}
              filter="complete"
              stats={stats.filterCounts ? stats.filterCounts.complete : emptyCount}
            />
            <SessionQualStat
              displayLabel="Incomplete"
              sessionId={sessionId}
              qualificationId={stats.qualificationId}
              filter="incomplete"
              stats={stats.filterCounts ? stats.filterCounts.incomplete : emptyCount}
            />
            <SessionQualStat
              displayLabel="With Absent"
              sessionId={sessionId}
              qualificationId={stats.qualificationId}
              filter="absent"
              stats={stats.filterCounts ? stats.filterCounts.absent : emptyCount}
            />
            <SessionQualStat
              displayLabel="With Missing"
              sessionId={sessionId}
              qualificationId={stats.qualificationId}
              filter="missing"
              stats={stats.filterCounts ? stats.filterCounts.missing : emptyCount}
            />
            <SessionQualStat
              displayLabel="Held"
              sessionId={sessionId}
              qualificationId={stats.qualificationId}
              filter="held"
              stats={stats.filterCounts ? stats.filterCounts.held : emptyCount}
            />
          </>
        )}
      </Box>
    </Box>
  </>
);
