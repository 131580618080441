import React from 'react';
import { Dialog } from '@material-ui/core';
import { ReportViewer } from './ReportViewer';

export interface ReportViewerModalProps {
  reportKey: string;
  reportVersion?: string;
  downloadFileName: string;
  isOpen: boolean;
  toggle: () => void;
}

export const ReportViewerModal: React.FC<ReportViewerModalProps> = ({
  isOpen,
  toggle,
  reportKey,
  reportVersion,
  downloadFileName,
}) => (
  <>
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={toggle}>
      <ReportViewer
        onClose={toggle}
        reportKey={reportKey}
        reportVersion={reportVersion}
        downloadFileName={downloadFileName}
      />
    </Dialog>
  </>
);
