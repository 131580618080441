import { useMemo } from 'react';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';
import { RunInfo } from '@cambridgeassessment/checkpoint-dtos';

export interface ReportInfoProps {
  sessionId: number;
  qualificationId: number;
}

export interface ReportInfoData {
  errorDownloadReportInfo: Error | null;
  downloadReportInfoData: RunInfo | undefined;
  downloadReportInfoAvailable: boolean;
}

export const useDownloadReportInfo = (reportInfo: ReportInfoProps): ReportInfoData => {
  const config = useApplicationEnv();

  const { sessionId, qualificationId } = reportInfo;

  const getReportInfoMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${sessionId}/qualifications/${qualificationId}/reportDownloadInfo`,
    };
  }, [config]);

  const getReportInfoTask = useAsyncTaskAxios<AxiosResponse<RunInfo>>(axios, getReportInfoMemo);

  useAsyncRun(config && getReportInfoTask);

  return {
    downloadReportInfoAvailable: !!getReportInfoTask.result?.data,
    downloadReportInfoData: getReportInfoTask.result?.data,
    errorDownloadReportInfo: getReportInfoTask.error,
  };
};
